<template>
	<div id="place">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>管理</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">场地设置</a></el-breadcrumb-item>
			</el-breadcrumb>
			<div class="content">
				<div class="u-f">
					<div class="left">
						<div class="add u-f-justify el-icon-plus" @click="addtime = true"></div>
						<div class="building-list">
							<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
							<el-checkbox-group v-model="building" @change="handleCheckedCitiesChange">
								<div v-for="items in placeList" :label="items.name" :key="items.id">
									<div class="u-f-item item u-f-jsb" @mouseover="items.isbtn = true" @mouseleave="items.isbtn = false">
										<div class="u-f-item">
											<div style="width: 20px;">
												<i
													v-if="items.schoolplacefloor.length"
													@click="items.isShow = !items.isShow"
													:class="items.isShow ? 'el-icon-caret-bottom' : 'el-icon-caret-right'"
												></i>
											</div>

											<el-checkbox :label="items.id" :key="items.id">
												<div>{{ items.name }}</div>
											</el-checkbox>
										</div>
										<div class="u-f-item btn" v-if="items.isbtn">
											<i class="el-icon-plus u-f-justify" @click.prevent @click.stop="editShow(items, 'add')"></i>
											<i class="el-icon-edit-outline u-f-justify" @click.prevent @click.stop="editShow(items)"></i>
											<i class="el-icon-delete u-f-justify" @click.prevent @click.stop="delPlace(items.id)"></i>
										</div>
									</div>
									<div class="floor-list" v-if="items.schoolplacefloor.length && items.isShow">
										<div
											class="floor-item u-f-item u-f-jsb"
											@mouseover="item.isbtn = true"
											@mouseleave="item.isbtn = false"
											:class="item.checked ? 'active' : ''"
											v-for="(item, i) in items.schoolplacefloor"
											:key="i"
										>
											<div>{{ item.name }}</div>
											<div class="u-f-item btn" v-if="item.isbtn">
												<i class="el-icon-edit-outline u-f-justify" @click.prevent @click.stop="editFloorShow(item)"></i>
												<i class="el-icon-delete u-f-justify" @click.prevent @click.stop="delPlaceFloor(item.id)"></i>
											</div>
										</div>
									</div>
								</div>
							</el-checkbox-group>
						</div>
					</div>
					<div class=" right">
						<div class="title u-f-item u-f-jsb">
							<div>
								共{{ total }}条数据
								<!-- <span style="color: #007AFF;">已选择0条</span> -->
							</div>
							<div class="u-f-item" style="padding-right: 10px;">
								<el-button size="small" @click="addroom=true">添加房间</el-button>
							</div>
						</div>
						<el-table
							:data="roomList"
							height="100%"
							ref="multipleTable"
							tooltip-effect="dark"
							@selection-change="handleSelectionChange"
							@filter-change="filterChange"
							style="width: 100%;border: 1px solid #EEEEEE;"
						>
							<el-table-column type="selection" width="60"></el-table-column>
							<el-table-column type="index" label="序号" width="150"></el-table-column>
							<el-table-column prop="schoolplace_name" label="建筑" column-key="schoolplace_name" :filter-method="filterHandler" :filters="filters"></el-table-column>
							<el-table-column prop="schoolplacefloor_name" label="楼层"></el-table-column>
							<el-table-column prop="name" label="房间名称"></el-table-column>
							<el-table-column prop="room_type_text" label="房间属性"></el-table-column>
							<el-table-column label="容纳人数" prop="capacity_type_text"></el-table-column>
							<el-table-column prop="class_brand" label="电子班牌"></el-table-column>
							<el-table-column label="操作" width="100">
								<template slot-scope="scope">
									<el-button @click="openDialog(scope.row)" type="text" size="small">编辑</el-button>
									<el-button @click="del_room(scope.row.id)" type="text" size="small">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<!-- <div class="u-f-item u-f-right" style="padding: 15px 0;">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page.sync="page"
								:page-size="limit"
								layout="total, prev, pager, next"
								:total="total"
							></el-pagination>
						</div> -->
					</div>
				</div>
			</div>
			<!-- 添加场地 -->
			<el-dialog title="添加场地" :visible.sync="addtime" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal" style="margin-left: 15px;">
							添加
						</div>
						<el-button @click="addSchools">添加校区</el-button>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							场地名称
						</div>
						<el-input v-model="placeName" placeholder="请输入场地名称" style="width: 75%;"></el-input>
						<!-- <i class="el-icon-remove-outline" v-if="schools.length > 1" @click="delSchool(index)"></i> -->
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addtime = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submit">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑场地 -->
			<el-dialog title="编辑场地" :visible.sync="editPlace" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal" style="margin-left: 15px;">
							添加
						</div>
						<el-button @click="addSchools">添加校区</el-button>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							场地名称
						</div>
						<el-input v-model="editPlaceName" placeholder="请输入场地名称" style="width: 75%;"></el-input>
						<!-- <i class="el-icon-remove-outline" v-if="schools.length > 1" @click="delSchool(index)"></i> -->
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editPlace = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitEdit">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加场地 -->
			<el-dialog title="添加楼层" :visible.sync="addfloor" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<!-- <div class="u-f-item input-box">
						<div class="input-lebal" style="margin-left: 15px;">
							添加
						</div>
						<el-button @click="addSchools">添加校区</el-button>
					</div> -->
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							楼层名称
						</div>
						<el-input v-model="floorName" placeholder="请输入楼层名称" style="width: 75%;"></el-input>
						<!-- <i class="el-icon-remove-outline" v-if="schools.length > 1" @click="delSchool(index)"></i> -->
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addfloor = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitFloor">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑楼层 -->
			<el-dialog title="编辑楼层" :visible.sync="editFloor" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal">
							<span>*</span>
							楼层名称
						</div>
						<el-input v-model="editFloorName" placeholder="请输入楼层名称" style="width: 75%;"></el-input>
						<!-- <i class="el-icon-remove-outline" v-if="schools.length > 1" @click="delSchool(index)"></i> -->
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editFloor = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitEditFloor">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 添加房间 -->
			<el-dialog title="添加房间" :visible.sync="addroom" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							建筑
						</div>
						<el-select v-model="school_place_id" placeholder="请选择建筑"  style="width: 75%;" @change="getFloorList">
							<el-option v-for="item in placeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							楼层
						</div>
						<el-select v-model="school_place_floor_id" placeholder="请选择建筑"  style="width: 75%;">
							<el-option v-for="item in floorList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							房间名称
						</div>
						<el-input v-model="roomName" placeholder="请输入房间名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							类型
						</div>
						<el-radio-group v-model="room_type" style="width: 75%;">
						    <el-radio :label="1" style="margin-bottom: 15px;">教室</el-radio>
						    <el-radio :label="2" style="margin-bottom: 15px;">实验室</el-radio>
						    <el-radio :label="3" style="margin-bottom: 15px;">办公室</el-radio>
							<el-radio :label="4" style="margin-bottom: 15px;">仓库</el-radio>
							<el-radio :label="5" style="margin-bottom: 15px;">功能室</el-radio>
							<el-radio :label="6" style="margin-bottom: 15px;">会议室</el-radio>
							<el-radio :label="7" style="margin-bottom: 15px;">其它</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							容纳人数
						</div>
						<el-radio-group v-model="capacity_type" style="width: 75%;">
						    <el-radio :label="1" style="margin-bottom: 15px;">10人内</el-radio>
						    <el-radio :label="2" style="margin-bottom: 15px;">11-20人</el-radio>
						    <el-radio :label="3" style="margin-bottom: 15px;">21-50人</el-radio>
							<el-radio :label="4" style="margin-bottom: 15px;">51-100人</el-radio>
							<el-radio :label="5" style="margin-bottom: 15px;">大于100人</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							电子班牌
						</div>
						<el-input v-model="class_brand" placeholder="请输入电子班牌" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="addroom = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submitaddroom">确 定</el-button>
				</span>
			</el-dialog>
			<!-- 编辑房间 -->
			<el-dialog title="编辑房间" :visible.sync="editroom" width="500px" :before-close="handleClose">
				<div class="edit-box">
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							建筑
						</div>
						<el-select v-model="school_place_id" placeholder="请选择建筑"  style="width: 75%;" @change="getFloorList">
							<el-option v-for="item in placeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							楼层
						</div>
						<el-select v-model="school_place_floor_id" placeholder="请选择建筑"  style="width: 75%;">
							<el-option v-for="item in floorList" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							房间名称
						</div>
						<el-input v-model="roomName" placeholder="请输入房间名称" style="width: 75%;"></el-input>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							类型
						</div>
						<el-radio-group v-model="room_type" style="width: 75%;">
						    <el-radio :label="1" style="margin-bottom: 15px;">教室</el-radio>
						    <el-radio :label="2" style="margin-bottom: 15px;">实验室</el-radio>
						    <el-radio :label="3" style="margin-bottom: 15px;">办公室</el-radio>
							<el-radio :label="4" style="margin-bottom: 15px;">仓库</el-radio>
							<el-radio :label="5" style="margin-bottom: 15px;">功能室</el-radio>
							<el-radio :label="6" style="margin-bottom: 15px;">会议室</el-radio>
							<el-radio :label="7" style="margin-bottom: 15px;">其它</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							容纳人数
						</div>
						<el-radio-group v-model="capacity_type" style="width: 75%;">
						    <el-radio :label="1" style="margin-bottom: 15px;">10人内</el-radio>
						    <el-radio :label="2" style="margin-bottom: 15px;">11-20人</el-radio>
						    <el-radio :label="3" style="margin-bottom: 15px;">21-50人</el-radio>
							<el-radio :label="4" style="margin-bottom: 15px;">51-100人</el-radio>
							<el-radio :label="5" style="margin-bottom: 15px;">大于100人</el-radio>
						</el-radio-group>
					</div>
					<div class="u-f-item input-box">
						<div class="input-lebal" style="text-align: right;width: 80px;">
							<span>*</span>
							电子班牌
						</div>
						<el-input v-model="class_brand" placeholder="请输入电子班牌" style="width: 75%;"></el-input>
					</div>
				</div>
				<span slot="footer" class="dialog-footer u-f-item u-f-jsb">
					<el-button @click="editroom = false" size="small">取 消</el-button>
					<el-button type="primary" size="small" @click="submiteditroom">确 定</el-button>
				</span>
			</el-dialog>
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: {
		index
	},
	data() {
		return {
			sousuo: '',
			condition: 1,
			conditionList: [],
			checkAll: false,
			isIndeterminate: true,
			building: [],
			placeList: [],
			roomList: [],
			filters: [],
			addtime: false,
			placeName: '',
			editPlaceName: '',
			editid: '',
			editPlace: false,
			page: 1,
			limit: 10,
			total: 0,
			addroom: false,
			addfloor: false,
			editFloor: false,
			editFloorName: '',
			floorName: '',
			editFloorId: '',
			school_place_id:'',
			floorList:[],
			school_place_floor_id:'',
			capacity_type:'',
			room_type:'',
			class_brand:'',
			roomName:'',
			roomId:'',
			editroom:false
		};
	},
	mounted() {
		this.getPlaceList()
		this.getPlaceRoomList()
	},
	methods: {
		handleCheckedCitiesChange(e) {
			console.log(this.building);
			this.getPlaceRoomList()
		},
		handleCheckAllChange(val) {
			let arr = [];
			for(let i in this.placeList){
				arr.push(this.placeList[i].id)
			}
			this.building = val ? arr : [];
			this.isIndeterminate = false;
			this.getPlaceRoomList()
		},
		handleClose(done) {
			done();
			this.initialize();
			this.info()
		},
		filterHandler(value, row, column) {
			const property = column['property'];
			return row[property] === value;
		},
		filterChange(filters) {
			if (filters.time_name.length === 0) {
				
			} else {
				
			}
		},
		// 打开弹窗
		openDialog(item) {
			this.roomId = item.id;
			this.school_place_id= item.school_place_id;
			this.getFloorList();
			this.school_place_floor_id= item.school_place_floor_id;
			this.roomName=item.name;
			this.room_type=Number(item.room_type);
			this.capacity_type=Number(item.capacity_type);
			this.class_brand=item.class_brand;
			this.editroom = true;
		},
		// 初始化数据
		initialize() {},
		// 获取场地列表
		getPlaceList() {
			this.$api.setting.getPlaceList({}).then(res => {
				if (res.data.code == 1) {
					let data = res.data.data.list;
					for (let i in data) {
						data[i].isShow = false;
						data[i].isbtn = false;
						for (let j in data[i].schoolplacefloor) {
							data[i].schoolplacefloor[j].checked = true;
							data[i].schoolplacefloor[j].isbtn = false;
						}
					}
					this.placeList = data;
					let arr = [];
					for (let i in this.placeList) {
						arr.push({
							text: this.placeList[i].name,
							value: this.placeList[i].name
						});
					}
					this.filters = arr;
				}
			});
		},
		// 添加场地
		submit() {
			this.$api.setting.addPlace({ name: this.placeName}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('添加成功');
					this.placeName = '';
					this.addtime = false;
					this.getPlaceList();
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		// 场地编辑
		editShow(item, op = '') {
			this.editPlaceName = item.name;
			this.editid = item.id;
			if (op == 'add') {
				this.addfloor = true;
			} else {
				this.editPlace = true;
			}
		},
		editFloorShow(item) {
			this.editFloorName = item.name;
			this.editFloorId = item.id;
			this.editFloor = true;
		},
		submitEdit() {
			this.$api.setting
				.editPlace({
					name: this.editPlaceName,
					id: this.editid
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('修改成功');
						this.editPlace = false;
						this.editPlaceName = '';
						this.editid = '';
						this.getPlaceList();
						this.getPlaceRoomList();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 删除场地
		delPlace(id) {
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delPlace({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getPlaceList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		delPlaceFloor(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delFloor({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getPlaceList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		},
		// 获取房间列表
		getPlaceRoomList() {
			let data = {};
			let data2 = {};
			if(this.building.length){
				data.school_place_id = this.building.toString()
				data2.filter = JSON.stringify(data);
				data2.op = {school_place_id:'in'}
			}
			this.$api.setting.getPlaceRoomList(data2).then(res => {
				if (res.data.code == 1) {
					this.roomList = res.data.data.room.rows;
					this.total = res.data.data.room.total;
				}
			});
		},
		handleSelectionChange(val) {
			let arr = [];
			for(let i in val){
				arr.push(val[i].id);
			}
			this.ids = arr;
			console.log(this.ids)
		},
		handleSizeChange(val) {
			console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page = val;
			this.getSectionList()
		},
		// 添加楼层
		submitFloor() {
			this.$api.setting
				.addFloor({
					name: this.floorName,
					school_place_id: this.editid
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('添加成功');
						this.addfloor = false;
						this.floorName = '';
						this.editid = '';
						this.getPlaceList();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 编辑楼层
		submitEditFloor() {
			this.$api.setting
				.editFloor({
					name: this.editFloorName,
					id: this.editFloorId
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('编辑成功');
						this.editFloor = false;
						this.getPlaceList();
						this.getPlaceRoomList();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		// 获取楼层列表
		getFloorList(){
			console.log(1111)
			this.$api.setting.getfloor({
				id:this.school_place_id
			}).then(res=>{
				if(res.data.code==1){
					this.floorList = res.data.data;
				}
			})
		},
		// 添加房间
		submitaddroom(){
			if(this.school_place_id==""||!this.school_place_id){return this.$message.error('请选择建筑')}
			if(this.school_place_floor_id==""||!this.school_place_floor_id){return this.$message.error('请选择楼层')}
			if(this.roomName==""||!this.roomName){return this.$message.error('请输入名称')}
			if(this.room_type==""||!this.room_type){return this.$message.error('请选择房间类型')}
			if(this.capacity_type==""||!this.capacity_type){return this.$message.error('请选择容纳人数')}
			if(this.class_brand==""||!this.class_brand){return this.$message.error('请输入电子班牌')}
			let data = {
				school_place_id:this.school_place_id,
				school_place_floor_id:this.school_place_floor_id,
				name:this.roomName,
				room_type:this.room_type,
				capacity_type:this.capacity_type,
				class_brand:this.class_brand
			}
			this.$api.setting.addRoom(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('添加成功')
					this.info()
					this.addroom = false;
					this.getPlaceRoomList()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		info(){
			this.school_place_id="";
			this.school_place_floor_id="";
			this.roomName="";
			this.room_type="";
			this.capacity_type="";
			this.class_brand="";
		},
		// 编辑房间
		submiteditroom(){
			if(this.school_place_id==""||!this.school_place_id){return this.$message.error('请选择建筑')}
			if(this.school_place_floor_id==""||!this.school_place_floor_id){return this.$message.error('请选择楼层')}
			if(this.roomName==""||!this.roomName){return this.$message.error('请输入名称')}
			if(this.room_type==""||!this.room_type){return this.$message.error('请选择房间类型')}
			if(this.capacity_type==""||!this.capacity_type){return this.$message.error('请选择容纳人数')}
			if(this.class_brand==""||!this.class_brand){return this.$message.error('请输入电子班牌')}
			let data = {
				id:this.roomId,
				school_place_id:this.school_place_id,
				school_place_floor_id:this.school_place_floor_id,
				name:this.roomName,
				room_type:this.room_type,
				capacity_type:this.capacity_type,
				class_brand:this.class_brand
			}
			this.$api.setting.editRoom(data).then(res=>{
				if(res.data.code==1){
					this.$message.success('编辑成功')
					this.info()
					this.editroom = false;
					this.getPlaceRoomList()
				}else{
					this.$message.error(res.data.msg)
				}
			})
		},
		// 删除房间
		del_room(id){
			this.$confirm('确定要删除？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(() => {
				this.$api.setting
					.delRoom({
						id: id
					})
					.then(res => {
						if (res.data.code == 1) {
							this.$message.success('删除成功');
							this.getPlaceRoomList();
						} else {
							this.$message.error(res.data.msg);
						}
					});
			});
		}
	}
};
</script>

<style lang="scss">
#place {
	.head {
		background-color: #ffffff;
		padding: 10px 30px;
		margin-top: 15px;
		.span {
			margin-right: 10px;
			background-color: #f9f9f9;
			color: #8d9da6;
			padding: 3px 10px;
		}
	}
	.content {
		margin-top: 20px;
		background-color: #ffffff;
		height: 85vh;
		.left {
			border-right: 1px solid #eeeeee;
			height: 85vh;
			min-width: 300px;
			.add {
				background-color: #f8f8f8;
				padding: 10px 0;
				font-size: 24px;
				color: #8d9da6;
				cursor: pointer;
			}
			.add:hover {
				color: #007aff;
			}
			.building-list {
				padding: 25px;
				height: 74vh;
				overflow-y: auto;
				.item {
					padding: 0 15px;
					height: 40px;
					line-height: 40px;
					margin-top: 15px;
					background-color: #f8f8f8;
					.el-icon-caret-right,
					.el-icon-caret-bottom {
						color: #a4a4a4;
						margin-right: 5px;
						font-size: 16px;
					}
					.el-checkbox {
						display: block;
					}
					.btn {
						i {
							background-color: #e8eaec;
							color: #a4a4a4;
							width: 25px;
							height: 25px;
							margin-left: 10px;
							border-radius: 50%;
						}
						i:hover {
							color: red;
						}
					}
				}
				.floor-list {
					background-color: #f8f8f8;
					border-top: 1px solid #eeeeee;
					padding: 0 15px 15px 30px;
					.floor-item {
						background-color: #f1f1f1;
						padding: 0 15px 0 25px;
						color: #4c5559;
						height: 40px;
						line-height: 40px;
						margin-top: 15px;
						.btn {
							i {
								background-color: #ffffff;
								color: #a4a4a4;
								width: 25px;
								height: 25px;
								margin-left: 10px;
								border-radius: 50%;
							}
							i:hover {
								color: red;
							}
						}
					}
					.active {
						background-color: #e4f1ff;
						color: #007aff;
					}
				}
			}
		}
		.right {
			height: 75vh;
			width: 100%;
			padding: 25px 50px;
			.title {
				padding: 0 0 15px 0;
			}
		}
	}

	.el-dialog__header {
		border-bottom: 1px solid #eeeeee;
		padding: 10px 20px;
		.el-dialog__title {
			font-size: 16px;
		}
	}
	.el-dialog__body {
		padding: 0 20px 35px 20px;
		.edit-box {
			.input-box {
				margin-top: 35px;
				padding-left: 15px;
				.input-lebal {
					color: #aeb5b8;
					margin-right: 10px;
					span {
						color: #ff7a65;
						margin-right: 3px;
					}
				}
				.el-icon-remove-outline {
					font-size: 1.5625vw;
					color: #d7d9db;
					margin-left: 10px;
				}
			}
			.avatar-uploader .el-upload {
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				cursor: pointer;
				position: relative;
				overflow: hidden;
			}
			.avatar-uploader .el-upload:hover {
				border-color: #409eff;
			}
			.avatar-uploader-icon {
				font-size: 28px;
				color: #8c939d;
				width: 90px;
				height: 90px;
				line-height: 90px;
				text-align: center;
			}
			.avatar {
				width: 90px;
				height: 90px;
				display: block;
			}
		}
	}
	.el-dialog__footer {
		padding: 15px 20px 15px;
		box-shadow: 0px -5px 10px -5px #eeeeee;
	}

	.el-table__column-filter-trigger {
		.el-icon-arrow-down:before {
			content: '\E790';
			font-size: 16px;
		}
	}
}
</style>
